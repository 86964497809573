import { logo, trillium } from "../src/assets/images";
import "./index.css";

export default function App() {
  return (
    <div className="flex flex-col min-h-screen px-6 py-10 pb-0  bg-white-500">
      {/* Header */}
      <header className="p-4">
        <title>SANDHILLS CENTER IS NOW PART OF TRILLIUM HEALTH RESOURCES</title>

        <div className="max-w-screen-xl mx-auto space-x-0 grid md:grid-cols-2 ">
          {/* Column 1 */}
          <div className="flex items-center justify-center p-4  bg-gradient-to-l bg-white">
            <a href="https://www.sandhillscenter.org/">
              <img src={logo} alt="SandHills Health Resources" />
            </a>
          </div>

          {/* Column 2 */}
          <div className="flex flex-col items-center justify-center p-4 bg-gradient-to-r">
            <a href="https://www.trilliumhealthresources.org/">
              <img src={trillium} alt="Trillium Health Resources" />
            </a>
          </div>
        </div>
      </header>
      {/* Main Content */}
      <div className="max-w-3xl mx-auto text-green-500 p-6 ">
        <h1 className="text-[40px] font-extrabold leading-[40px] tracking-tight text-[#428941] text-center">
          SANDHILLS CENTER IS NOW PART OF TRILLIUM HEALTH RESOURCES
        </h1>
      </div>
      {/* Action Buttons */}
      <div class="mt-1 flex justify-center">
        <a
          class="bg-green-600 text-white  py-3 px-10 rounded hover:bg-green-700 text-[30px]"
          href="https://screening.hfihub.com/accesspoint"
          target="_blank"
        >
          Click here to take a Confidential Screening{" "}
          <span>
            <i class="fa-solid fa-angle-right"></i>
          </span>
        </a>
      </div>
      <footer className="text-center text-[20px] py-4 bottom-0">
        <p>2024 - Sandhills Center Legacy</p>
      </footer>
    </div>
  );
}
